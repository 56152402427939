.navbar {
    /* position: -webkit-sticky; */
    top: 0;
    left: 0;
    right: 0;
    height: 8vh;
    width: 100%;
    font-family: "MontserratRegular";
    background-color: #FF0000;
    display: flex;
    flex-direction: row;
    /* z-index: 9999; */
}

.img {
    display: flex;
    flex: 1;
    margin: auto 2% auto 2%;
    cursor: pointer;
    height: 80%;
}

.img :hover {
    transform: scale(1.01);
}

.middle {
    display: flex;
    flex: 6;
    align-items: center;
    width: 100%;
}

.profile1 {
    display: flex;
    /* flex: 1; */
    margin: auto 2% auto 2%;
    cursor: pointer;
    /* width: 5px; */
    height: 75%;
}

.searchbar {
    height: 30px;
    width: 35%;
    display: flex;
    flex-direction: row;
    border-width: 2px;
    border-style: solid;
    border-color: white;
    border-radius: 22.5px;
    align-items: center;
    font-family: 'MontserratRegular';
    background-color: transparent;
    padding: 2px;
    color: white;
    cursor: pointer;
}

.searchbar-icon {
    width: 20px;
    height: 20px;
    margin: auto 2% auto 3%;
}

.searchbar-input {
    border-width: 0px;
    background-color: transparent;
    outline: none;
    color: white;
    cursor: pointer;
    width: 100%;
}

.searchbar-input::placeholder {
    color: white;
    font-family: 'MontserratRegular';
}

.searchbar-input.onfocus {
    border-color: white;
}

.icon {
    display: flex;
    flex-direction: row;
    flex: 1;
    width: 40px;
    height: 80%;
    margin: 5px 10px 5px auto;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
}

@media (max-width: 768px) {
    .searchbar {
        width: 90%;
        border-width: 0px;
        border-color: transparent;
        font-size: small;
    }
    .searchbar-input {
        width: 100%;
        height: 40%;
    }
}