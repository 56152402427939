body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif,'MontserratRegular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'MontserratRegular',
    monospace;
}

@font-face {
  font-family: 'MontserratRegular';
  src: local('MontserratRegular'),
  url('./fonts/Montserrat-Regular.ttf') format("truetype");
}
@font-face {
  font-family: 'MontserratLite';
  src: local('MontserratLite'),
  url('./fonts/Montserrat-Light.ttf') format("truetype");
}
@font-face {
  font-family: 'MontserratMedium';
  src: local('MontserratMedium'),
  url('./fonts/Montserrat-Medium.ttf') format("truetype");
}
@font-face {
  font-family: 'MontserratBold';
  src: local('MontserratBold'),
  url('./fonts/Montserrat-Bold.ttf') format("truetype");
}
@font-face {
  font-family: 'MontserratSemiBold';
  src: local('MontserratSemiBold'),
  url('./fonts/Montserrat-SemiBold.ttf') format("truetype");
}

