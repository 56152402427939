.button {
    width: 100%;
    margin-top: 10px;
    height: 33px;
    border: 1px solid #FF0000;
    border-radius: 5px;
    font-size: 14px;
    font-family: MontserratRegular;
    background-color: #FF0000;
    border-radius: "10px";
    color: #FFFFFF;
    
}
.button:hover{
    background-color: #d1413a;
    /* box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25); */
    cursor: pointer;
}
.button:active{
    background-color: #b9342d;
}
