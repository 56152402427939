.login_bg video {
    position: absolute;
    top: 0;
    z-index: -1;
    height: 100%;
    width: 100%;
    object-fit: cover;
    overflow: hidden;
}

.login_bg {
    width: 100%;
    border: 2px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    overflow: hidden;
}
